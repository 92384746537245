const calculateNewVal = (val, scalePercent) => {
  let newVal = val;
  newVal = val / 100 * scalePercent;
  newVal = newVal < 1 ? 1 : newVal;
  return newVal;
};

/**
 * Calculate the new value for an inline style value and respect the scaling options.
 */
function scaleMobile(_ref, useWidthOfThisContainerId, original, replaceWith) {
  let {
    enabled,
    scalePercent,
    scalePercentVertical
  } = _ref;
  let additionalVerticalScaling = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */
  }
  return original;
}
export { scaleMobile };